import { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowScroll } from 'react-use';
import useEffectAfterMount from './useEffectAfterMount';
export default function useWindowScrollEnd() {
    var scroll = useWindowScroll();
    var isEndReached = useCallback(function () {
        return ((window.innerHeight + window.scrollY) * 1.1 > document.body.offsetHeight);
    }, []);
    var _a = useState(isEndReached), end = _a[0], setEnd = _a[1];
    // Detect body height change to reset the end state and prevent endless loop
    var observer = useRef(new ResizeObserver(function () {
        setEnd(isEndReached());
    }));
    useEffect(function () {
        var current = observer.current;
        current.observe(document.body);
        return function () {
            current.unobserve(document.body);
        };
    }, []);
    // Update end state when scrolling
    useEffectAfterMount(function () {
        setEnd(isEndReached());
    }, [scroll, isEndReached]);
    return end;
}
