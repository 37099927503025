import faker from 'faker';
import capitalize from 'lodash/capitalize';

export function createDocumentDetail({ year, contractId }) {
  const participantId = randomNumber().toString();

  return {
    Participant_Identifier: participantId,
    Description_DocType: 'CFDI Listo para enviar',
    Contract_Id_SOB: contractId,
    Participant_Name: `PARTICIPANTE NO. ${participantId}`,
    Contact_Email: faker.internet.email(),
    Amount: randomNumber(),
    Contribution: randomNumber(),
    Interest: randomNumber(),
    Withdrawal: randomNumber(),
    SendMaiDate: new Date(),
    Document_Id: 1,
    Product_Name: faker.lorem.word(),
    Fiscal_Year: year,
    NameFile: faker.lorem.word(),
  };
}

export function createDocumentHead(total) {
  return {
    Total_Participants: total,
    Status: 'CFDI Listo para enviar',
    MailsAdmin: faker.internet.email(),
    Total_Monto_Timbrado: randomNumber(),
  };
}

export function createDocumentRequestDetail({ year, contractId }) {
  const participantId = randomNumber().toString();

  return {
    participant_id: participantId,
    clave: `C${participantId}`,
    contract_id: contractId,
    contract_sob: contractId,
    contribution: randomNumber(),
    curp: faker.datatype.uuid(),
    document_id: randomNumber(),
    email: faker.internet.email(),
    fiscal_year: year,
    interest: randomNumber(),
    loan: randomNumber(),
    name_file: randomNumber(),
    name: `PARTICIPANTE NO. ${participantId}`,
    rfc_origin: faker.datatype.uuid(),
    rfc: faker.datatype.uuid(),
    status: 'CFDI Listo para enviar',
    total: randomNumber(),
  };
}

export function createDocumentRequestHead(total) {
  return {
    status: 'CFDI Listo para enviar',
    total_rows: total,
    total_interest: total,
    total_contribution: total,
    total_loan: faker.internet.email(),
    grand_total: total,
  };
}

export function createClient() {
  const clientId = randomNumber().toString();

  return {
    Client_Id: clientId,
    Client_Name: `${clientId} - CLIENTE NO. ${clientId}`,
  };
}

export function createContract(clientId) {
  return {
    Client_Id: clientId,
    Contract_Id: randomNumber().toString(),
    Contract_Name: capitalize(faker.lorem.word()),
    Product_Name: capitalize(faker.lorem.word()),
    Prefix: faker.lorem.word().toUpperCase(),
  };
}

export function createVestingConfig({
  clientId,
  contract,
  accountDescription,
}) {
  return {
    rules: {
      no_rules: [{ value: 0.0 }, { value: 1.0 }, { value: 2.0 }],
      age: [
        { min: 0.0, max: 0.0 },
        { min: 0.0, max: 0.0 },
        { min: 0.0, max: 0.0 },
      ],
      seniority: [
        { min: 5.0, max: 5.0 },
        { min: 6.0, max: 6.0 },
        { min: 7.0, max: 7.0 },
      ],
      contribution_time: [
        { min: 0.0, max: 0.0 },
        { min: 0.0, max: 0.0 },
        { min: 0.0, max: 0.0 },
      ],
      percents: [{ value: 20.0 }, { value: 40.0 }, { value: 60.0 }],
    },
    client_id: clientId,
    id: faker.datatype.uuid(),
    status: 'ACTIVE',
    description: faker.lorem.word(),
    accounts: [{ type_collection: [], description: accountDescription }],
    client_name: `${clientId} - CLIENTE NO. ${clientId}`,
    contracts: [contract.Contract_Id],
    _contracts: [contract],
    fecha: '1/20/2021 5:03:37 PM',
    is_template: false,
    pension: { no_rule: 0 },
    name: faker.lorem.word(),
    pension_before: { no_rule: 0, msg_pension_before: null },
  };
}

export function createClientAccountDetail() {
  return {
    description: faker.lorem.word(),
    type_collection: [
      {
        id_contract: randomNumber(),
        id: randomNumber(),
        id_parent: randomNumber(),
      },
    ],
  };
}

export function createUntimelyInvoice({ contractId, namePrefix, number }) {
  const consecutive = number.toString().padStart('4', '0');

  return {
    file_name: `${namePrefix}${contractId}${consecutive}`,
    participant_name: faker.name.findName(),
    participant_identifier: faker.datatype.uuid(),
    rfc: faker.lorem.word(),
  };
}

export function randomNumber({ min = 1000, max = 99999 } = {}) {
  // reserve numbers from 0 - 1000 to create well-known fixures
  return faker.datatype.number({ min, max });
}
