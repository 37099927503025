import faker from 'faker';

import randomNumber from '../randomNumber';

type CreateInvoiceInput = {
  year: number;
  contractId: number;
};

export default function createInvoice({
  year,
  contractId,
}: CreateInvoiceInput) {
  const participantId = randomNumber().toString();

  return {
    participantId,
    contractId,
    clientId: randomNumber(),
    contractIdSob: contractId,
    productId: randomNumber(),
    rfc: faker.lorem.word(),
    curp: faker.lorem.word(),
    externalKey: `CLAVE ${participantId}`,
    name: `PARTICIPANTE NO. ${participantId}`,
    email: faker.internet.email(),
    interest: randomNumber(),
    zipCode: randomNumber().toString(),
    contribution: randomNumber(),
    withdrawal: randomNumber(),
    total: randomNumber(),
    documentId: randomNumber(),
    period: year,
    status: 'PROCESSED',
    originRfc: faker.lorem.word(),
    fileName: faker.lorem.word(),
  };
}
