/* eslint-disable camelcase */
import { isEmpty } from '@recordkeeping/common/build/array';

import ParamError from '../ParamError';
import { parseNumericBody } from './parseNumericParam';
import { InvoicePostBody } from './types';

export default function parseInvoicePostBody(
  body: Record<string, any>,
): [InvoicePostBody | undefined, Error | null] {
  try {
    const fiscalYear = parseNumericBody(body, 'fiscal_year')!;
    const contracts = (body.contracts as string)
      .split(',')
      .map((str) => str.trim());

    if (isEmpty(contracts)) {
      throw new ParamError('contracts', body.contracts);
    }

    const participantsInvoicesData = body.participants.map(
      createParticipantInvoiceDataFromParticipant,
    ) as Array<{
      participantId: number;
      retention?: number;
      withdrawal?: number;
    }>;

    return [
      {
        fiscalYear,
        contracts,
        participantsInvoicesData,
      },
      null,
    ];
  } catch (err) {
    // @ts-ignore
    return [undefined, err];
  }
}

function createParticipantInvoiceDataFromParticipant(participant: {
  retention: number;
  withdrawal: number;
  participant_id: number;
}) {
  if (
    participant.retention == null ||
    participant.withdrawal == null ||
    Number.isNaN(participant.participant_id)
  ) {
    throw new ParamError(
      'participant.participant_id',
      participant.participant_id.toString(),
    );
  }

  if (
    participant.retention == null ||
    participant.withdrawal == null ||
    Number.isNaN(participant.retention)
  ) {
    throw new ParamError(
      'participant.retention',
      participant.retention.toString(),
    );
  }

  return {
    participantId: participant.participant_id,
    retention: participant.retention,
    withdrawal: participant.withdrawal,
  };
}
