import { useEffectAfterMount } from '@recordkeeping/common/build/hooks';
import PropTypes from 'prop-types';
import React, { useState, useContext, createContext } from 'react';
import { useLocation } from 'react-router-dom';
import useBeforeUnload from 'react-use/lib/useBeforeUnload';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import store from 'store';

import { useClientSelection } from 'clients/ClientSelectionContext';

import { YEAR_PADDING } from './constants';

export const InvoiceFieldsContext = createContext(null);

export function useInvoiceFields() {
  const context = useContext(InvoiceFieldsContext);
  if (!context) {
    throw new Error(
      'useInvoiceFields must be used within a InvoiceFieldsProvider',
    );
  }

  return context;
}

const initialState = {
  fiscalYear: new Date().getFullYear() - YEAR_PADDING,
  contractIds: new Set(),
};

export function InvoiceFieldsProvider({ children }) {
  const location = useLocation();
  const { clientId } = useClientSelection();

  // Create a key per location where InvoiceFieldsProvider used
  // and preserve state integrity with clientId
  const memoryKey = `InvoiceFieldsProvider-${location.pathname.replace(
    '/',
    '',
  )}-${clientId}`;

  // Controles if filters have ben modified after submit
  const [isTouched, setIsTouched] = useState(false);
  const [fiscalYear, setFiscalYear] = useLocalStorage(
    `${memoryKey}-fiscalYear`,
    initialState.fiscalYear,
  );
  const [contractIds, setContractIds] = useLocalStorage(
    `${memoryKey}-contractIds`,
    initialState.contractIds,
    /* @ts-ignore */
    {
      serializer: (value) => JSON.stringify(Array.from(value)),
      deserializer: (value) => new Set(JSON.parse(value)),
    },
  );

  const [isValid, setIsValid] = useState(() => validateFields());

  // Clean local storage when user leaves
  /* @ts-ignore */
  useBeforeUnload(resetFilters);

  // Reset filters when client selection change
  useEffectAfterMount(resetFilters, [clientId]);

  useEffectAfterMount(() => {
    setIsValid(validateFields());
    setIsTouched(true);
  }, [contractIds, fiscalYear]);

  function validateFields() {
    /* @ts-ignore */
    return contractIds.size > 0 && !!fiscalYear;
  }

  // TODO: Replace with `utils/update`
  function toggleContract(contract) {
    const updatedContracts = new Set(contractIds);
    if (updatedContracts.has(contract.Contract_Id)) {
      updatedContracts.delete(contract.Contract_Id);
    } else {
      updatedContracts.add(contract.Contract_Id);
    }

    setContractIds(updatedContracts);
  }

  function resetFilters() {
    setFiscalYear(initialState.fiscalYear);
    setContractIds(initialState.contractIds);
    setIsTouched(false);
    resetStorage();
  }

  function resetStorage() {
    store.each((_, key) => {
      if (key.includes('InvoiceFieldsProvider')) {
        store.remove(key);
      }
    });
  }

  return (
    <InvoiceFieldsContext.Provider
      /* @ts-ignore */
      value={{
        contractIds,
        fiscalYear,
        isValid,
        isTouched,
        setIsTouched,
        resetFilters,
        setFiscalYear,
        toggleContract,
      }}
    >
      {children}
    </InvoiceFieldsContext.Provider>
  );
}

InvoiceFieldsProvider.propTypes = {
  children: PropTypes.node,
};
