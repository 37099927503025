import { InvoiceStatus } from 'invoices/InvoiceRequest';

import ParamError from '../ParamError';

export default function parseInvoiceStatus(
  params: URLSearchParams,
  paramName: string,
  optional: boolean = false,
): InvoiceStatus | undefined {
  const paramValue = String(params.get('paramName'));
  if (paramValue !== 'PROCESSED' && paramValue !== 'ERROR' && !optional) {
    throw new ParamError(paramName, paramValue);
  }

  return paramValue as InvoiceStatus | undefined;
}
