import { enableMapSet, produce } from 'immer';
enableMapSet();
/**
 * update wraps side-effects on data structures
 * preventing accidental mutations
 * allowing you to apply imperative operations
 *
 * @template T
 * @param {T} state
 * @param {function} fn
 * @returns {T}
 *
 * @example
 * const a = [1, 2, 3]
 * const b = update(a, aCopy => {
 *   aCopy.push(4)
 * })
 *
 * a => [1, 2, 3]
 * b => [1, 2, 3, 4]
 */
export default produce;
