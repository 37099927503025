import { Grid, Icon, Typography } from '@gbm/queen-ui-guidelines';
import { useTranslation } from '@recordkeeping/common/build/internationalization/InternationalizationContext';
import React from 'react';
import { Link } from 'react-router-dom';

export default function AccessDenied() {
  const t = useTranslation();

  return (
    <Grid container={true} className="mt-4">
      <Grid row={true} justify="center">
        <Grid md={8} lg={6} xl={5}>
          <div className="card">
            <div className="card-body p-4">
              <div className="error-ghost text-center">
                <div className="display-1">
                  <Icon className="remixicon-error-warning-fill" />
                </div>
              </div>

              <div className="text-center">
                <Typography component="h1">
                  {t('phrases.accessDenied')}
                </Typography>
                <Typography variant="textMuted" className="mb-4 mt-3">
                  {t('phrases.accessDeniedMessage')}
                </Typography>

                <Link className="btn btn-primary mt-3" to="/">
                  <Icon className="mdi mdi-reply mr-1"></Icon>{' '}
                  {t('phrases.returnHome')}
                </Link>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
