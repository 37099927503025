import PropTypes from 'prop-types';
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import clsx from 'clsx';

import { Icon } from '@gbm/queen-ui-guidelines';

import styles from './sidebar.module.scss';

const Item = ({ to, label, icon, small, links }) => {
  const [activeLink, setActiveLink] = useState(false);

  const [openSubItems, setOpenSubItems] = useState(false);
  const [isOpenSubMenuItem, setIsOpenSubMenuItem] = useState(false);

  const location = useLocation();

  const [, locationIndex] = location.pathname.split('/');

  const handelIsActive = ({ match }) => {
    setActiveLink(!!match);
  };

  const handleOpenSubMenu = () => {
    setIsOpenSubMenuItem(!isOpenSubMenuItem);

    setOpenSubItems(!openSubItems);
  };

  const isOpenSubItems = (route) => {
    if (locationIndex === route.replace('/', '')) {
      return setOpenSubItems(true);
    }
  };

  const isActiveSubItem = (route) => {
    if (locationIndex === route.replace('/', '')) {
      return true;
    }

    return false;
  };

  const classesLink = clsx({
    [styles.item_link]: true,
    [styles.item_link__active]: activeLink,
  });

  const classesLabel = clsx({
    [styles.link_label]: true,
    [styles.link_label__small]: small,
    [styles.link_label__show]: small,
  });

  const classesIconSub = clsx('ml-2', {
    'fas fa-caret-up': openSubItems,
    'fas fa-caret-down': !openSubItems,
    [styles.subItem_icon]: true,
    [styles.subItem_icon__small]: small,
    [styles.subItem_icon__show]: small,
  });

  const classesSubItemLabel = clsx({
    [styles.subItem_label]: true,
    [styles.subItem_label__small]: small,
    [styles.subItem_label__show]: small,
  });

  const classesIcon = clsx({
    [`mr-2 ${icon} gbm-sidebar__icon`]: true,
  });

  if (links.length > 0) {
    return (
      <li>
        <ul className={styles.subItem_list}>
          <li>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <div
              id="sub-menu-item"
              className={styles.subItem_container}
              onClick={handleOpenSubMenu}
            >
              <Icon className={classesIcon} />
              <span className={classesSubItemLabel}>{label}</span>
              <Icon className={classesIconSub} />
            </div>

            <div
              id="subitems-list"
              className={clsx({
                [styles.show]: openSubItems,
                [styles.hide]: !openSubItems,
              })}
            >
              {links.map((link) => {
                if (!isOpenSubMenuItem && !openSubItems) {
                  isOpenSubItems(link.to);
                }

                return (
                  <NavLink
                    key={link.to}
                    to={link.to}
                    className={clsx({
                      [styles.subItem_link]: true,
                      [styles.subItem_link__active]: isActiveSubItem(link.to),
                      [styles.subItem_link__small]: small,
                      [styles.subItem_link__show]: small,
                    })}
                  >
                    <Icon
                      className={clsx({
                        [`mr-2 ${link.icon}`]: true,
                      })}
                    />
                    <span className={classesLabel}> {link.label} </span>
                  </NavLink>
                );
              })}
            </div>
          </li>
        </ul>
      </li>
    );
  }

  return (
    <li>
      <NavLink
        to={to}
        className={classesLink}
        /* @ts-ignore */
        isActive={(match) => {
          handelIsActive({ match });
        }}
      >
        <Icon className={classesIcon} />
        <span className={classesLabel}> {label} </span>
      </NavLink>
    </li>
  );
};

Item.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.node,
  small: PropTypes.bool,
  links: PropTypes.array,
};

Item.defaultProps = {
  links: [],
};

export default Item;
