var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// TODO: move as a backoffice implementation
import { useCallback, useMemo } from 'react';
import AppError from '../Error';
import { useAnalytics } from '../analytics/AnalyticsContext';
import useConfig from '../config';
import { useDataSource } from '../data/DataContext';
import { useMonitoring } from '../monitoring/MonitoringContext';
import useStore from '../storage/useStorage';
import { AuthProvider } from './AuthContext';
import decodeAuthToken from './decodeAuthToken';
// FIXME: find a better way to pass token key between GBM context
var ACCESS_TOKEN_MEMORY_KEY = 'access_token';
var EXPIRES_IN_MEMORY_KEY = 'expires_in';
var ID_TOKEN_MEMORY_KEY = 'id_token';
var REFRESH_TOKEN_MEMORY_KEY = 'refresh_token';
export default function GBMAuthProvider(props) {
    var _this = this;
    var authData = useDataSource('auth');
    var recordKeepingData = useDataSource('recordKeeping');
    var storage = useStore();
    var monitoring = useMonitoring();
    var analytics = useAnalytics();
    var config = useConfig();
    var login = useCallback(function (code) { return __awaiter(_this, void 0, void 0, function () {
        var data, decoded, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (code == null) {
                        return [2 /*return*/, window.location.replace("".concat(config.EP_AUTH_URL, "/login?client_id=").concat(config.EP_AUTH_CLIENT_ID, "&response_type=code&redirect_uri=").concat(config.EP_AUTH_REDIRECT_URI))];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, authData.requestToken(code)];
                case 2:
                    data = _a.sent();
                    storage.set(ACCESS_TOKEN_MEMORY_KEY, data.access_token);
                    storage.set(EXPIRES_IN_MEMORY_KEY, data.expires_in);
                    storage.set(ID_TOKEN_MEMORY_KEY, data.id_token);
                    storage.set(REFRESH_TOKEN_MEMORY_KEY, data.refresh_token);
                    decoded = decodeAuthToken(data.id_token);
                    if (decoded.email == null) {
                        throw new Error('invalid email');
                    }
                    // HACK: rkVestings requires an email address to be set
                    storage.set('email', { value: decoded.email });
                    analytics.identify(decoded.email);
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    if (err_1 instanceof Error) {
                        monitoring.notifyError(err_1);
                        throw new AppError("login error, cause", err_1);
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [
        analytics,
        authData,
        config.EP_AUTH_CLIENT_ID,
        config.EP_AUTH_REDIRECT_URI,
        config.EP_AUTH_URL,
        monitoring,
        storage,
    ]);
    var getFederation = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, {
                    clientId: undefined,
                    federationProviderTypeId: undefined,
                    providerName: undefined,
                }];
        });
    }); }, []);
    var checkAuth = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var token;
        return __generator(this, function (_a) {
            token = storage.get(ID_TOKEN_MEMORY_KEY);
            if (token == null) {
                return [2 /*return*/, false];
            }
            return [2 /*return*/, !isTokenExpired(token)];
        });
    }); }, [storage]);
    var logout = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            storage.remove(ID_TOKEN_MEMORY_KEY);
            return [2 /*return*/];
        });
    }); }, [storage]);
    var getPermissions = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var token, decoded, permissions;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = storage.get(ID_TOKEN_MEMORY_KEY);
                    decoded = decodeAuthToken(token);
                    if (decoded.email == null) {
                        throw new Error('invalid email');
                    }
                    return [4 /*yield*/, recordKeepingData.userIdentity.getUserIdentityPermissions(decoded.email)];
                case 1:
                    permissions = _a.sent();
                    return [2 /*return*/, new Set(permissions.map(function (permission) { return permission.key; }))];
            }
        });
    }); }, [recordKeepingData.userIdentity, storage]);
    var getIdentity = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var token, _a, _, identity;
        return __generator(this, function (_b) {
            token = storage.get(ID_TOKEN_MEMORY_KEY);
            if (token == null) {
                throw new AppError("invalid token id");
            }
            _a = decodeAuthToken(token), _ = _a.exp, identity = __rest(_a, ["exp"]);
            return [2 /*return*/, identity];
        });
    }); }, [storage]);
    function isTokenExpired(token) {
        var decoded = decodeAuthToken(token);
        if (decoded.exp == null) {
            return true;
        }
        return new Date(decoded.exp) < new Date();
    }
    var value = useMemo(function () { return ({
        login: login,
        getFederation: getFederation,
        isLoggingIn: false,
        hasLoginError: false,
        checkAuth: checkAuth,
        logout: logout,
        getPermissions: getPermissions,
        getIdentity: getIdentity,
    }); }, [login, getFederation, checkAuth, logout, getPermissions, getIdentity]);
    return _jsx(AuthProvider, { value: value, children: props.children });
}
