import { Feature } from '@recordkeeping/common/build/featureFlags';
import { Route, Router, Switch } from '@recordkeeping/common/build/navigation';
import React from 'react';

import { ClientSelectionProvider } from '../clients/ClientSelectionContext';
import AccessDenied from '../components/AccessDenied';
import EndSession from '../components/EndSession';
import Home from '../components/Home';
import Layout from '../components/Layout';
import SignIn from '../components/SignIn';
import { InvoiceFieldsProvider } from '../fiscalRegulation/InvoiceFieldsContext';

import InfrastructureProvider from './InfrastructureProvider';

const Distributions = React.lazy(
  () => import('../distributions/Distributions'),
);
const Vestings = React.lazy(() => import('../pensionPlans/Vestings'));
const VestingResume = React.lazy(() => import('../pensionPlans/VestingResume'));
const Invoices = React.lazy(() => import('../invoices/Invoices'));
const InvoicesRequest = React.lazy(() => import('../invoices/InvoicesRequest'));
const CreateVesting = React.lazy(() => import('../pensionPlans/CreateVesting'));
const UntimelyInvoices = React.lazy(
  () => import('../fiscalRegulation/UntimelyInvoices'),
);

export default function App() {
  return (
    <InfrastructureProvider>
      <Router>
        <Route exact={true} path="/signin" component={SignIn} />
        <Route exact={true} path="/access-denied" component={AccessDenied} />
        <Route exact={true} path="/end-session" component={EndSession} />

        <Layout>
          <Route exact={true} path="/" component={Home} />

          <ClientSelectionProvider key="pension-plans">
            <Switch>
              <Route exact={true} path="/vestings" component={Vestings} />
              <Route
                exact={true}
                path="/vestings/new"
                component={CreateVesting}
              />
              <Route
                exact={true}
                path="/vestings/:id"
                component={VestingResume}
              />
              <Route
                exact={true}
                path="/vestings/:id/edit"
                component={CreateVesting}
              />
            </Switch>
          </ClientSelectionProvider>

          <ClientSelectionProvider key="fiscal-regulation">
            <Route exact={true} path="/cfdis">
              <Invoices />
            </Route>
            <Route exact={true} path="/cfdis-requests">
              <InvoicesRequest />
            </Route>
            <Route exact={true} path="/untimely-cfdis">
              <InvoiceFieldsProvider key="untimely-cfdis">
                <UntimelyInvoices />
              </InvoiceFieldsProvider>
            </Route>
          </ClientSelectionProvider>

          <ClientSelectionProvider key="distributions">
            <Feature feature="DISTRIBUTIONS">
              <Route exact={true} path="/distributions">
                <Distributions />
              </Route>
            </Feature>
          </ClientSelectionProvider>
        </Layout>
      </Router>
    </InfrastructureProvider>
  );
}
