/* eslint-disable camelcase */
import sample from 'lodash/sample';
import times from 'lodash/times';

import { GetInvoicesResponseBody } from 'api/types';

import { PAGE_SIZE, TOTAL_ITEMS } from '../constrants';
import createInvoice from './createInvoice';
import { InvoiceGetBody } from './types';

const store = {};

export default function createInvoiceGetResponseBody(
  body: InvoiceGetBody,
): GetInvoicesResponseBody {
  const key = `createInvoiceGetResponseBody${JSON.stringify(body)}`;

  if (store[key]) {
    return store[key];
  }

  const pageSize = body.pageSize ?? PAGE_SIZE;
  const totalPages = Math.ceil(TOTAL_ITEMS / pageSize);

  if (body?.page == null) {
    // eslint-disable-next-line no-param-reassign
    body.page = 1;
  }

  let next = '';
  if (body.page < totalPages) {
    next = `?page=${body.page! + 1}&page_size=${body.pageSize}`;
  }

  let previous = '';
  if (body.page > 1) {
    previous = `?page=${body.page! - 1}&page_size=${body.pageSize}`;
  }

  if (body.page > totalPages) {
    return {
      items: [],
      pagination_metadata: {
        next,
        previous,
        total_items: TOTAL_ITEMS,
        page_size: pageSize,
        page_count: 0,
        page: body.page,
      },
    };
  }

  const items = times(pageSize > TOTAL_ITEMS ? TOTAL_ITEMS : pageSize, () =>
    createInvoice({
      year: body.fiscalYear,
      contractId: parseInt(sample(body.contracts)!, 10),
    }),
  ).map(createInvoiceResponseFromInvoice);

  if (body.page === 1) {
    items[0] = createInvoiceResponseFromInvoice({
      clientId: 1,
      contractId: parseInt(body.contracts[0], 10),
      contractIdSob: parseInt(body.contracts[0], 10),
      contribution: 1000,
      curp: 'SOMECURP',
      documentId: 1,
      email: '10003@email.com',
      externalKey: `CLAVE C10003`,
      fileName: 'some/path/name',
      interest: 2000,
      zipCode: '55770',
      withdrawal: 3000,
      name: `PARTICIPANTE NO. 10003`,
      originRfc: 'SOMERFC',
      participantId: '10003',
      productId: 1,
      rfc: 'SOMERFC',
      status: 'ERROR',
      total: 4000,
    });
  }

  const response = {
    items,
    pagination_metadata: {
      next,
      previous,
      total_items: TOTAL_ITEMS,
      page_size: pageSize,
      page_count: items.length,
      page: body.page,
    },
  };

  store[key] = response;

  // @ts-ignore
  return response;
}

export function createInvoiceResponseFromInvoice({
  participantId,
  clientId,
  contractId,
  contractIdSob,
  productId,
  externalKey,
  zipCode,
  documentId,
  originRfc,
  fileName,
  ...invoice
}) {
  return {
    ...invoice,
    participant_id: participantId,
    client_id: clientId,
    contract_id: contractId,
    contract_id_sob: contractIdSob,
    product_id: productId,
    external_key: externalKey,
    zip_code: zipCode,
    document_id: documentId,
    origin_rfc: originRfc,
    file_name: fileName,
  };
}
