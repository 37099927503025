var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback, useContext } from 'react';
import createContextSlice from '../createContextSlice';
import { capitalize, upperFirst } from '../string';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var _a = createContextSlice(undefined, 'internationalization'), InternationalizationProvider = _a[0], _ = _a[1], InternationalizationContext = _a[2];
function useTranslation(dictionary) {
    var value = useContext(InternationalizationContext);
    if (value == null) {
        throw new Error('useTranslation must be used within a InternationalizationProvider');
    }
    return useCallback(function (phrase, options) {
        if (options === void 0) { options = {}; }
        var transform = options.transform, rest = __rest(options, ["transform"]);
        return transformString(value.translate(phrase, __assign({ dictionary: dictionary }, rest)), transform);
    }, [value, dictionary]);
}
function transformString(str, transform) {
    switch (transform) {
        case 'uppercase':
            return str.toUpperCase();
        case 'lowercase':
            return str.toLowerCase();
        case 'capitalize':
            return capitalize(str);
        case 'upperFirst':
            return upperFirst(str);
        default:
            return str;
    }
}
export { InternationalizationProvider, useTranslation };
