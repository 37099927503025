import { useEffect, useRef } from 'react';
export default function useEffectAfterMount(callback, deps) {
    var justMounted = useRef(true);
    useEffect(function () {
        if (!justMounted.current) {
            return callback();
        }
        justMounted.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
