import ParamError from '../ParamError';

export default function parseNumericParam(
  params: URLSearchParams,
  paramName: string,
  optional: boolean = false,
): number | undefined {
  const paramValue = params.get(paramName) ?? '';
  const parsedValue = parseInt(paramValue, 10) ?? 0;
  if (Number.isNaN(parsedValue) && !optional) {
    throw new ParamError(paramName, paramValue);
  }

  return parsedValue;
}

export function parseNumericBody(
  body: Record<string, any>,
  paramName: string,
  optional: boolean = false,
): number | undefined {
  const paramValue = body[paramName];
  const parsedValue = parseInt(paramValue, 10);
  if (Number.isNaN(parsedValue) && !optional) {
    throw new ParamError(paramName, paramValue);
  }

  return parsedValue;
}
