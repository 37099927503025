import { Authenticated } from '@recordkeeping/common/build/auth';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Grid } from '@gbm/queen-ui-guidelines';
import clsx from 'clsx';

import Header from 'components/Header';
import Sidebar from 'components/Sidebar';

import styles from './layout.module.scss';

export default function Layout({ children }) {
  const [small, setSideBarSize] = useState(false);

  const classesPage = clsx({
    [styles.page]: true,
    [styles.page_small]: small,
  });

  const toggleSidebarSize = (state) => () => {
    return setSideBarSize(!state);
  };

  return (
    <Authenticated redirect="/signin">
      <div id="wrapper">
        <Header onSmall={toggleSidebarSize(small)} small={small} />
        <Sidebar small={small} />
        <div className={classesPage}>
          <Grid className="container-fluid">{children}</Grid>
        </div>
      </div>
    </Authenticated>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};
