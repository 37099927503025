var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { getScreenSize } from '../useMediaQuery';
import useResponsiveProp from '../useResponsiveProp';
var ContentBlockStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  max-width: ", "px;\n  margin: auto;\n"], ["\n  width: 100%;\n  max-width: ", "px;\n  margin: auto;\n"])), function (props) { return props.width; });
export default function ContentBlock(_a) {
    var children = _a.children, width = _a.width;
    var widthProp = useResponsiveProp(width);
    return (_jsx(ContentBlockStyle, { width: mapSize(widthProp), children: children }));
}
function mapSize(size) {
    if (typeof size === 'string') {
        return getScreenSize(size);
    }
    return size;
}
var templateObject_1;
