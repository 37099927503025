import chunk from 'lodash/chunk';
import diff from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import times from 'lodash/times';
import update from './update';
function first(arr) {
    return arr[0];
}
function last(arr) {
    return arr[arr.length - 1];
}
function sort(arr, compareFn) {
    return update(arr, function (draftArr) {
        return draftArr.sort(function (a, b) {
            if (typeof compareFn === 'function') {
                return compareFn(a, b);
            }
            // treated as an object key
            var key = compareFn;
            var opA = a[key];
            var opB = b[key];
            if (opA.toLowerCase) {
                opA = opA.toLowerCase();
                opB = opB.toLowerCase();
            }
            if (opA > opB)
                return 1;
            if (opA < opB)
                return -1;
            return 0;
        });
    });
}
function reverse(arr) {
    return update(arr, function (draftArr) { return draftArr.reverse(); });
}
export { diff, last, sort, reverse, first, chunk, isEqual, isEmpty, times };
