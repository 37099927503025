import createContextSlice from '../createContextSlice';
var _a = createContextSlice(undefined, 'data'), DataProvider = _a[0], useAllDataSources = _a[1];
export function useDataSource(sourceName) {
    var allDataSources = useAllDataSources();
    if (allDataSources == null) {
        throw new Error('DataProvider is not available');
    }
    return allDataSources[sourceName];
}
export { DataProvider, useAllDataSources };
