import { usePermissions } from '@recordkeeping/common/build/auth/AuthContext';
import {
  Feature,
  useFeatureFlags,
} from '@recordkeeping/common/build/featureFlags';
import { useTranslation } from '@recordkeeping/common/build/internationalization';
import { Box, Padding, Stack } from '@recordkeeping/common/build/layout';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import Skeleton from 'components/Skeleton';

import Item from './Item';
import styles from './sidebar.module.scss';

function Sidebar({ small }) {
  const t = useTranslation();
  const flags = useFeatureFlags();
  const { permissions, loading } = usePermissions();

  const classesRoot = clsx({
    [styles.sidebar]: true,
    [styles.sidebar_small]: small,
  });

  const classesTitle = clsx({
    [styles.title]: true,
    [styles.hide]: small,
  });

  const pensionPlans = (
    <Item
      label={t('navigation.linkPlanPension')}
      icon="remixicon-bank-fill"
      small={small}
      links={[
        permissions?.has('pensionPlans.vestings.view')
          ? [
              {
                to: '/vestings',
                label: t('navigation.linkPlanPensionDAQuery'),
                icon: 'remixicon-briefcase-2-line',
              },
            ]
          : [],
      ].flat()}
    />
  );

  const fiscalRegulation = (
    <Item
      label={t('navigation.linkRegulacionFiscal')}
      icon="remixicon-article-line"
      small={small}
      links={[
        flags.INVOICE_AUTOMATION &&
        permissions?.has('fiscalRegulation.invoicesRequest.view')
          ? [
              {
                to: '/cfdis-requests',
                label: t('navigation.linkCargaCFDIs'),
                icon: 'remixicon-table-line',
              },
            ]
          : [],
        permissions?.has('fiscalRegulation.invoicesSend.view')
          ? [
              {
                to: '/cfdis',
                label: t('navigation.linkEnvioCFDIs'),
                icon: 'remixicon-send-plane-line',
              },
            ]
          : [],
        flags.UNTIMELY_INVOICES
          ? [
              {
                to: '/untimely-cfdis',
                label: t('navigation.linkCFDIsExtemporaneos'),
                icon: 'remixicon-send-plane-line',
              },
            ]
          : [],
      ].flat()}
    />
  );

  return (
    <div className={classesRoot}>
      <ul className={styles.menu_list}>
        <li className={classesTitle}>{t('navigation.navigation')}</li>

        {loading ? (
          <Box width="full">
            <Padding padding="none" paddingLeft="md" paddingRight="md">
              <Stack>
                <Skeleton height={40} />
                <Skeleton height={40} />
                <Skeleton height={40} />
              </Stack>
            </Padding>
          </Box>
        ) : (
          <>
            {permissions?.has('pensionPlans.view') ? pensionPlans : null}
            {permissions?.has('fiscalRegulation.view')
              ? fiscalRegulation
              : null}
            <Feature feature="DISTRIBUTIONS">
              <Item
                to="/distributions"
                label={t('generate distribution', { transform: 'upperFirst' })}
                icon="remixicon-bank-fill"
                small={small}
              />
            </Feature>
          </>
        )}
      </ul>
    </div>
  );
}

Sidebar.propTypes = {
  small: PropTypes.bool,
};

export default Sidebar;
