import { isEmpty } from '@recordkeeping/common/build/array';

import ParamError from '../ParamError';
import parseInvoiceStatus from './parseInvoiceStatus';
import parseNumericParam from './parseNumericParam';
import { InvoiceGetBody } from './types';

export default function parseInvoiceGetBody(
  params: URLSearchParams,
): [InvoiceGetBody | undefined, Error | null] {
  try {
    const fiscalYear = parseNumericParam(params, 'fiscal_year')!;
    const client = parseNumericParam(params, 'client')!;
    const page = parseNumericParam(params, 'page', true);
    const pageSize = parseNumericParam(params, 'page_size', true);
    const status = parseInvoiceStatus(params, 'status', true);

    const contracts = (params.get('contracts') as string)
      .split(',')
      .map((str) => str.trim());

    if (isEmpty(contracts)) {
      throw new ParamError('contracts', params.get('contracts') ?? '');
    }

    return [
      {
        fiscalYear,
        contracts,
        page,
        pageSize,
        status,
        client,
      },
      null,
    ];
  } catch (err) {
    // @ts-ignore
    return [undefined, err];
  }
}
