import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export type ClientSelection = { id: string; name: string };

type State = {
  clientId?: string;
  client?: ClientSelection;
  setClient: (clientSelection: ClientSelection) => void;
  cleanClient: () => void;
};

const ClientSelectionContext = createContext<State | undefined>(undefined);

type ClientSelectionProviderProps = {
  children: ReactNode;
  client?: ClientSelection;
  // eslint-disable-next-line react/no-unused-prop-types
  key?: string;
};

export function ClientSelectionProvider({
  children,
  client: initialState,
}: ClientSelectionProviderProps) {
  const [client, setClient] = useState<ClientSelection | undefined>(
    initialState,
  );

  const cleanClient = useCallback(() => {
    setClient(undefined);
  }, []);

  const value = useMemo(() => {
    return {
      client,
      setClient,
      cleanClient,
      clientId: client?.id,
    };
  }, [client, cleanClient, setClient]);

  return (
    <ClientSelectionContext.Provider value={value}>
      {children}
    </ClientSelectionContext.Provider>
  );
}

export function useClientSelection() {
  const context = useContext(ClientSelectionContext);
  if (!context) {
    throw new Error(
      'useClientSelection must be used within a ClientSelectionProvider',
    );
  }

  return context;
}
