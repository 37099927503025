import { jsx as _jsx } from "react/jsx-runtime";
import { camelCase } from 'lodash';
import { createContext, useContext } from 'react';
import createHOC from './createHOC';
import { pascalCase } from './string';
export default function createContextSlice(initialValue, valueName) {
    if (valueName === void 0) { valueName = 'value'; }
    var BaseContext = createContext(undefined);
    var BaseProvider = BaseContext.Provider;
    var providerDisplayName = pascalCase("".concat(valueName, " provider"));
    var contextDisplayName = camelCase("".concat(valueName, " context"));
    var useValueDisplayName = camelCase("use ".concat(valueName));
    function Provider(_a) {
        var value = _a.value, children = _a.children;
        var concreteValue = initialValue !== null && initialValue !== void 0 ? initialValue : value;
        if (concreteValue === undefined) {
            throw new Error("".concat(providerDisplayName, " was not provided with a concrete value"));
        }
        return _jsx(BaseProvider, { value: concreteValue, children: children });
    }
    function useValue() {
        var context = useContext(BaseContext);
        if (context === undefined) {
            throw new Error("".concat(useValueDisplayName, " must be used within a ").concat(providerDisplayName));
        }
        return context;
    }
    var withProvider = createHOC(Provider);
    Provider.displayName = providerDisplayName;
    useValue.displayName = useValueDisplayName;
    BaseContext.displayName = contextDisplayName;
    return [Provider, useValue, BaseContext, withProvider];
}
