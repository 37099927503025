import faker from 'faker';

type RandomNumberOption = {
  min: number;
  max: number;
};

// reserve numbers from 0 - 1000 to create well-known fixures
export default function randomNumber(
  { min, max }: RandomNumberOption = { min: 1000, max: 99999 },
) {
  return faker.datatype.number({ min, max });
}
