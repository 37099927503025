import { useTranslation } from '@recordkeeping/common/build/internationalization/InternationalizationContext';
import { useHistory } from '@recordkeeping/common/build/navigation';
import Link from '@recordkeeping/common/build/navigation/Link';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  useAuth,
  useIdentity,
} from '@recordkeeping/common/build/auth/AuthContext';

import { Dropdown, DropdownItem, Icon, Label } from '@gbm/queen-ui-guidelines';

import IconGbmLogoSmall from 'assets/images/logo-rk-white.png';
import IconGbmLogo from 'assets/images/logo-rk.png';

import styles from './header.module.scss';

export default function Header({ onSmall, small }) {
  const [openDropDown, setDropDownState] = useState(false);
  const auth = useAuth();
  const { identity } = useIdentity();
  const t = useTranslation();
  const history = useHistory();

  const classesLogo = clsx({
    [styles.logo_container]: true,
    [styles.logo_container__small]: small,
  });

  const classesLogoLg = clsx({
    [`img-fluid ${styles.logo_lg}`]: true,
    [styles.hide]: small,
  });

  const classesLogoSm = clsx({
    [`img-fluid ${styles.logo_small}`]: true,
    [styles.show]: small,
  });

  function toggleDropDown() {
    setDropDownState((prevState) => !prevState);
  }

  function handleLogout() {
    auth?.logout();
    history.replace('/end-session');
  }

  return (
    <header className={styles.root}>
      <div className={styles.nav}>
        <div className={classesLogo}>
          <Link href="/">
            <img src={IconGbmLogo} alt="gbm" className={classesLogoLg} />
            <img src={IconGbmLogoSmall} alt="gbm" className={classesLogoSm} />
          </Link>
        </div>

        <button type="button" className={styles.menu} onClick={onSmall}>
          <Icon className="fe-menu" />
        </button>

        <div className={styles.spacer} />

        <div className={styles.dropdown_wrapper}>
          <Dropdown
            right={true}
            id="dropdown"
            variant="button"
            open={openDropDown}
            className={styles.dropdown}
            label={identity?.username}
            color="light"
            onClick={toggleDropDown}
            iconButton={
              openDropDown ? (
                <Icon className="fas fa-chevron-up ml-1" />
              ) : (
                <Icon className="fas fa-chevron-down ml-1" />
              )
            }
          >
            <DropdownItem>
              <Label>{identity?.email}</Label>
            </DropdownItem>
            <DropdownItem onClick={handleLogout}>
              <Icon className="remixicon-logout-box-line mr-2" />
              {t('navigation.signout')}
            </DropdownItem>
          </Dropdown>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  small: PropTypes.bool,
  onSmall: PropTypes.func,
};
