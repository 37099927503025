export const EXCEL_CURRENCY_FORMAT = '"$"#,##0;[Red]-"$"#,##0';
export const EXCEL_FILE_NAME = 'Envíos_CFDI';
export const INVISIBLE_CHARACTER = ' ';
export const NA = 'N/A';
export const REQUEST_EXCEL_FILE_NAME = 'Carga_CFDIs';
export const REQUEST_EXCEL_TEMPLATE_FILE_NAME = 'Carga_CFDIs_Plantilla';
export const ROWS_PER_PAGE = 10;
export const TABLE_COLUMNS = 10;
export const TOTALS_SKELETON_ROWS = 1;
export const YEAR_PADDING = 2;
export const ENTER_KEY_CODE = 13;
export const UNTIMELY_INVOICES_EXCEL_FILE_NAME = 'CFDIs_Extemporaneos';
