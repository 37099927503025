var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import useAsync from 'react-use/lib/useAsync';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import Rox from 'rox-browser/dist/rox-browser.js';
import { FeatureFlagProvider } from './FeatureFlagContext';
var flagsContainer = {
    CLONADO_DA: new Rox.Flag(false),
    UNTIMELY_INVOICES: new Rox.Flag(false),
    PORTAL_V2: new Rox.Flag(false),
    RESET_PASSWORD: new Rox.Flag(false),
    ALLOW_FEDERATION_LOGIN: new Rox.Flag(false),
    INVOICE_AUTOMATION: new Rox.Flag(true),
    BALANCE_RESUME: new Rox.Flag(false),
    LOAN_APPLICATION: new Rox.Flag(false),
    REPORT: new Rox.Flag(false),
    ADMIN_REPORTS: new Rox.Flag(false),
    INDIVIDUAL_BALANCE_REPORTS: new Rox.Flag(false),
    DISTRIBUTIONS: new Rox.Flag(false),
    BASKET: new Rox.Flag(false),
    LOAN_APPLICATION_LIST: new Rox.Flag(false),
    ENABLE_INVOICE_RESEND: new Rox.Flag(false),
    CHANGE_PASSWORD: new Rox.Flag(false),
    RK_PORTAL_CONTRACT_RESUME: new Rox.Flag(false),
    RK_PORTAL_ACCOUNT_RESUME_HIDE_BALANCES: new Rox.Flag(false),
    RK_LOAN_REQUEST: new Rox.Flag(false),
    RK_MOBILE_APP_LOGIN_BANNER: new Rox.Flag(false),
    GOOGLE_FEDERATION_LOGIN: new Rox.Flag(false),
};
export default function RollOutProvider(_a) {
    var _this = this;
    var children = _a.children, token = _a.token;
    var _b = useLocalStorage("email", undefined), context = _b[0], setContext = _b[1];
    useAsync(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
                Rox.register(flagsContainer);
                Rox.setup(token);
            }
            catch (error) {
                console.error('Error initializing Rox:', error);
            }
            return [2 /*return*/];
        });
    }); }, [token]);
    var value = {
        setContext: setContext,
        CLONADO_DA: flagsContainer.CLONADO_DA.isEnabled(context),
        UNTIMELY_INVOICES: flagsContainer.UNTIMELY_INVOICES.isEnabled(context),
        PORTAL_V2: flagsContainer.PORTAL_V2.isEnabled(context),
        RESET_PASSWORD: flagsContainer.RESET_PASSWORD.isEnabled(context),
        ALLOW_FEDERATION_LOGIN: flagsContainer.ALLOW_FEDERATION_LOGIN.isEnabled(context),
        INVOICE_AUTOMATION: flagsContainer.INVOICE_AUTOMATION.isEnabled(context),
        BALANCE_RESUME: flagsContainer.BALANCE_RESUME.isEnabled(context),
        LOAN_APPLICATION: flagsContainer.LOAN_APPLICATION.isEnabled(context),
        REPORT: flagsContainer.REPORT.isEnabled(context),
        ADMIN_REPORTS: flagsContainer.ADMIN_REPORTS.isEnabled(context),
        INDIVIDUAL_BALANCE_REPORTS: flagsContainer.INDIVIDUAL_BALANCE_REPORTS.isEnabled(context),
        DISTRIBUTIONS: flagsContainer.DISTRIBUTIONS.isEnabled(context),
        BASKET: flagsContainer.BASKET.isEnabled(context),
        LOAN_APPLICATION_LIST: flagsContainer.LOAN_APPLICATION_LIST.isEnabled(context),
        ENABLE_INVOICE_RESEND: flagsContainer.ENABLE_INVOICE_RESEND.isEnabled(context),
        CHANGE_PASSWORD: flagsContainer.CHANGE_PASSWORD.isEnabled(context),
        RK_PORTAL_CONTRACT_RESUME: flagsContainer.RK_PORTAL_CONTRACT_RESUME.isEnabled(context),
        RK_PORTAL_ACCOUNT_RESUME_HIDE_BALANCES: flagsContainer.RK_PORTAL_ACCOUNT_RESUME_HIDE_BALANCES.isEnabled(context),
        RK_LOAN_REQUEST: flagsContainer.RK_LOAN_REQUEST.isEnabled(context),
        RK_MOBILE_APP_LOGIN_BANNER: flagsContainer.RK_MOBILE_APP_LOGIN_BANNER.isEnabled(context),
        GOOGLE_FEDERATION_LOGIN: flagsContainer.GOOGLE_FEDERATION_LOGIN.isEnabled(context),
    };
    return _jsx(FeatureFlagProvider, { value: value, children: children });
}
