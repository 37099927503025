import { Skeleton as BaseSkeleton } from '@gbm/queen-ui-guidelines';
import React from 'react';

export default function Skeleton(props) {
  return (
    <span data-testid="skeleton">
      <BaseSkeleton {...props} />
    </span>
  );
}
