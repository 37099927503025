import { Card, Typography } from '@gbm/queen-ui-guidelines';
import { useTranslation } from '@recordkeeping/common/build/internationalization/InternationalizationContext';
import { useHistory } from '@recordkeeping/common/build/navigation';
/* eslint-disable react/no-unused-state */
import React, { useEffect } from 'react';

import {
  Box,
  ContentBlock,
  Padding,
  Stack,
} from '@recordkeeping/common/build/layout';

import IconGbmLogo from 'assets/images/logo-rk.png';
import IconSvg from './IconSvg';

const TIMEOUT_MS = 3000;

export default function EndSession() {
  const t = useTranslation();
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.replace('/signin');
    }, TIMEOUT_MS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Padding padding="lg" paddingTop="xxl">
      <ContentBlock width={475}>
        <Card>
          <Padding padding="xxl">
            <Box align="center">
              <Stack space="lg">
                <img src={IconGbmLogo} alt="gbm" className="img-fluid" />
                <div className="logout-checkmark">
                  <IconSvg />
                </div>
                <Typography align="center" component="h3">
                  {t('phrases.expiredSession')}
                </Typography>
                <Typography align="center" variant="textMuted">
                  {t('phrases.enterAgain')}
                </Typography>
              </Stack>
            </Box>
          </Padding>
        </Card>
      </ContentBlock>
    </Padding>
  );
}
