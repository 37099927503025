import Text from '@recordkeeping/common/build/Text';
import Kudy from 'kudyjs';
import React from 'react';

import {
  ContentBlock,
  Padding,
  Stack,
} from '@recordkeeping/common/build/layout';

import styles from './styles.module.scss';

function Home() {
  const { phrase, author } = Kudy.make();

  return (
    <ContentBlock width={1024}>
      <Padding padding="sm" paddingTop={['sm', 'lg']}>
        <Stack>
          <span className={styles.divider} />
          <Text component="blockquote" size={['2rem', '4.8rem']}>
            {phrase}
          </Text>
          <Text color="#6c757d" align="right" size={['1rem', '2.4rem']}>
            {author}
          </Text>
        </Stack>
      </Padding>
    </ContentBlock>
  );
}

export default Home;
