import wait from '@recordkeeping/common/build/wait';
import faker from 'faker';
import { http, HttpResponse } from 'msw';

export default [
  http.post(
    'https://api.recordkeeping.dev-gbm.com/v2/clients/:clientId/contracts/:contractId/transactions/distribution',
    async () => {
      await wait();
      return HttpResponse.json({ id: faker.datatype.uuid() }, { status: 201 });
    },
  ),
];
