var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';
import useResponsiveProp from '../useResponsiveProp';
var PaddingStyle = styled(PadBox)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: inherit;\n  display: inherit;\n  width: inherit;\n  height: inherit;\n"], ["\n  position: inherit;\n  display: inherit;\n  width: inherit;\n  height: inherit;\n"])));
export default function Padding(_a) {
    var children = _a.children, padding = _a.padding, paddingBottom = _a.paddingBottom, paddingLeft = _a.paddingLeft, paddingRight = _a.paddingRight, paddingTop = _a.paddingTop;
    var paddingBottomProp = useResponsiveProp(paddingBottom !== null && paddingBottom !== void 0 ? paddingBottom : padding);
    var paddingLeftProp = useResponsiveProp(paddingLeft !== null && paddingLeft !== void 0 ? paddingLeft : padding);
    var paddingRightProp = useResponsiveProp(paddingRight !== null && paddingRight !== void 0 ? paddingRight : padding);
    var paddingTopProp = useResponsiveProp(paddingTop !== null && paddingTop !== void 0 ? paddingTop : padding);
    return (_jsx(PaddingStyle, { padding: {
            left: paddingLeftProp,
            right: paddingRightProp,
            top: paddingTopProp,
            bottom: paddingBottomProp,
        }, children: children }));
}
var templateObject_1;
