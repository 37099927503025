import wait from '@recordkeeping/common/build/wait';
/* eslint-disable camelcase */
import { http, HttpResponse } from 'msw';

import {
  getClientAccountDetail,
  getClients,
  getConfigs,
  getContracts,
  getDocumentsDetail,
  getDocumentsHead,
  getDocumentsRequestDetail,
  getDocumentsRequestHead,
  getUntimelyInvoices,
} from './fetchers';

import {
  getConfigParams,
  getDocumentsDetailParams,
  getDocumentsRequestDetailParams,
} from './utils';

export const handlers = [
  http.get(
    'https://api.recordkeeping.dev-gbm.com/v1/backoffice/fiscalregulation/documents-detail',
    ({ request }) => {
      const params = getDocumentsDetailParams(request);
      const details = getDocumentsDetail(params);
      return HttpResponse.json(details);
    },
  ),

  http.get(
    'https://api.recordkeeping.dev-gbm.com/v1/backoffice/fiscalregulation/documents-head',
    ({ request }) => {
      const head = getDocumentsHead();
      return HttpResponse.json(head);
    },
  ),

  http.get(
    'https://api.recordkeeping.dev-gbm.com/v1/backoffice/fiscalregulation/documentsrequest-detail',
    ({ request }) => {
      const params = getDocumentsRequestDetailParams(request);
      const detailRequest = getDocumentsRequestDetail(params);
      return HttpResponse.json(detailRequest);
    },
  ),

  http.post(
    'https://api.recordkeeping.dev-gbm.com/v1/backoffice/fiscalregulation/documentsrequest-detail',
    () => {
      return HttpResponse.json({});
    },
  ),

  http.get(
    'https://api.recordkeeping.dev-gbm.com/v1/backoffice/fiscalregulation/documentsrequest-head',
    () => {
      const requestHead = getDocumentsRequestHead();
      return HttpResponse.json(requestHead);
    },
  ),

  http.get(
    'https://api.recordkeeping.dev-gbm.com/v1/backoffice/vestings/clients',
    ({ request }) => {
      const clients = getClients();
      return HttpResponse.json(clients);
    },
  ),

  http.get(
    'https://api.recordkeeping.dev-gbm.com/v1/backoffice/vestings/contracts',
    ({ request }) => {
      const url = new URL(request.url);
      const clientId = url.searchParams.get('client_id');
      const contracts = getContracts({ clientId });
      return HttpResponse.json(contracts);
    },
  ),

  http.post(
    'https://api.recordkeeping.dev-gbm.com/v1/backoffice/fiscalregulation/notifications',
    async () => {
      await wait(2000);
      return HttpResponse.json({});
    },
  ),

  http.post(
    'https://api.recordkeeping.dev-gbm.com/v1/backoffice/documents/notifications',
    () => {
      return HttpResponse.json({});
    },
  ),

  http.get(
    'https://api.recordkeeping.dev-gbm.com/v1/vestings/config',
    ({ request }) => {
      const params = getConfigParams(request);
      const config = getConfigs(params);
      return HttpResponse.json(config);
    },
  ),

  http.get(
    'https://api.recordkeeping.dev-gbm.com/v1/backoffice/vestings/accounts-detail',
    ({ request }) => {
      const url = new URL(request.url);
      const clientId = url.searchParams.get('client_id');
      const accountDetail = getClientAccountDetail({ clientId });
      return HttpResponse.json(accountDetail);
    },
  ),

  http.post('https://api.recordkeeping.dev-gbm.com/v1/vestings/config', () => {
    return HttpResponse.json({});
  }),

  http.patch(
    'https://api.recordkeeping.dev-gbm.com/v1/vestings/config/:id',
    () => {
      return HttpResponse.json({});
    },
  ),

  http.post(
    'https://api.recordkeeping.dev-gbm.com/v1/vestings/valuation',
    () => {
      return HttpResponse.json({ invalid_participants: [] });
    },
  ),

  http.delete(
    'https://api.recordkeeping.dev-gbm.com/v1/vestings/valuation',
    () => {
      return HttpResponse.json({});
    },
  ),

  http.post(
    'https://api.recordkeeping.dev-gbm.com/v1/backoffice/fiscalregulation/extemporaneous',
    ({ request }) => {
      const {
        /* @ts-ignore */
        client_id: clientId,
        /* @ts-ignore */
        contract_id: contractId,
        /* @ts-ignore */
        name_prefix: namePrefix,
        /* @ts-ignore */
        begin_range: beginRange,
        /* @ts-ignore */
        end_range: endRange,
      } = request.body;

      const untimelyInvoices = getUntimelyInvoices({
        clientId,
        contractId,
        namePrefix,
        beginRange,
        endRange,
      });

      return HttpResponse.json(untimelyInvoices);
    },
  ),
];
