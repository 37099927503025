import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { MonitoringProvider } from './MonitoringContext';
import localMonitoringClient from './localMonitoringClient';
import useNewRelic from './useNewRelic';
export default function NewRelicProvider(_a) {
    var children = _a.children, _b = _a.enable, enable = _b === void 0 ? false : _b;
    var newRelic = useNewRelic(enable);
    // use fallback monitoring client when newRelic is not ready
    var value = useMemo(function () { return newRelic !== null && newRelic !== void 0 ? newRelic : localMonitoringClient; }, [newRelic]);
    return _jsx(MonitoringProvider, { value: value, children: children });
}
