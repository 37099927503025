import sample from 'lodash/sample';
import times from 'lodash/times';

import {
  createClient,
  createClientAccountDetail,
  createContract,
  createDocumentDetail,
  createDocumentHead,
  createDocumentRequestDetail,
  createDocumentRequestHead,
  createUntimelyInvoice,
  createVestingConfig,
  randomNumber,
} from './factories';

import {
  client,
  clientAccountDetail,
  clientConfig,
  contract,
  detail,
  detailRequest,
} from './fixures';

const store = {};

const MAX_PAGE =
  process.env.NODE_ENV === 'test'
    ? process.env.REACT_APP_MSW_MAX_PAGE ?? 20
    : process.env.REACT_APP_MSW_MAX_PAGE ?? 10000;

export function getDocumentsDetail({ rowsIni, rowsPage, year, contractsIds }) {
  const key = `documents-detail-${rowsIni}-${rowsPage}`;

  if (store[key]) {
    return store[key];
  }

  // prevent infinite load forever
  if (rowsIni >= MAX_PAGE) {
    return [];
  }

  const contractIds = contractsIds.split('-');
  const details = times(rowsPage, () =>
    createDocumentDetail({ year, contractId: sample(contractIds) }),
  );

  if (
    rowsIni === 0 &&
    contractIds.some((id) => id === detail.Contract_Id_SOB)
  ) {
    // Replace first item with well-known
    details[0] = detail;
  }

  store[key] = details;
  return details;
}

export function getDocumentsHead() {
  const key = `documents-head`;

  if (store[key]) {
    return store[key];
  }

  const head = [createDocumentHead(MAX_PAGE)];
  store[key] = head;
  return head;
}

export function getDocumentsRequestDetail({
  rowsIni,
  rowsPage,
  year,
  contractsIds,
}) {
  const key = `documents-request-detail-${rowsIni}-${rowsPage}`;

  if (store[key]) {
    return store[key];
  }

  // prevent infinite load forever
  if (rowsIni >= MAX_PAGE) {
    return [];
  }

  const contractIds = contractsIds.split('-');
  const detailRequests = times(rowsPage, () =>
    createDocumentRequestDetail({ year, contractId: sample(contractIds) }),
  );

  if (
    rowsIni === 0 &&
    contractIds.some((id) => id === detailRequest.contract_sob)
  ) {
    // Replace first item with well-known config
    // @ts-ignore
    detailRequests[0] = detailRequest;
  }

  store[key] = detailRequests;
  return detailRequests;
}

export function getDocumentsRequestHead() {
  const key = `documents-request-head`;

  if (store[key]) {
    return store[key];
  }

  const headRequest = [createDocumentRequestHead(MAX_PAGE)];
  store[key] = headRequest;
  return headRequest;
}

export function getClients() {
  const key = `clients`;

  if (store[key]) {
    return store[key];
  }

  const clients = [client, ...times(20, createClient)];
  store[key] = clients;
  return clients;
}

export function getContracts({ clientId }) {
  const key = `contracts-${clientId}`;

  if (store[key]) {
    return store[key];
  }

  const contracts = times(3, () => createContract(clientId));

  // Replace first item with well-known config
  contracts[0] = contract;

  store[key] = contracts;
  return contracts;
}

export function getConfigs({ clientId = randomNumber().toString() } = {}) {
  const key = 'client-config';

  if (store[key]) {
    return store[key];
  }

  const contracts = getContracts({ clientId });
  const accountDescriptions = getClientAccountDetail({ clientId }).map(
    (a) => a.description,
  );

  const totalItems = 3;
  const items = times(totalItems, () =>
    createVestingConfig({
      clientId,
      contract: sample(contracts),
      accountDescription: sample(accountDescriptions),
    }),
  );

  // Replace first item with well-known config
  /* @ts-ignore */
  items[0] = clientConfig;

  const clientConfigs = {
    items,
    pagination_metadata: {
      total_items: totalItems,
      page_size: 20,
      page: 1,
    },
  };

  store[key] = clientConfigs;
  return clientConfigs;
}

export function getClientAccountDetail({ clientId }) {
  const key = `client-account-detail-${clientId}`;

  if (store[key]) {
    return store[key];
  }

  const clientAccountDetails = [
    clientAccountDetail,
    ...times(1, createClientAccountDetail),
  ];

  store[key] = clientAccountDetails;
  return clientAccountDetails;
}

export function getUntimelyInvoices({
  clientId,
  contractId,
  namePrefix,
  beginRange,
  endRange,
}) {
  const key = `untimely-invoices-${clientId}-${contractId}-${namePrefix}-${beginRange}-${endRange}`;
  if (store[key]) {
    return store[key];
  }

  const items = endRange - beginRange + 1;
  const processedCount = randomNumber({ min: 0, max: items });
  const unprocessedCount = items - processedCount;

  const untimelyInvoices = {
    client_id: clientId,
    contract_id: contractId,
    name_prefix: namePrefix,
    begin_range: beginRange,
    end_range: endRange,
    processed: {
      count: processedCount,
      detail: times(processedCount, (index) =>
        createUntimelyInvoice({
          contractId,
          namePrefix,
          number: index + beginRange,
        }),
      ),
    },
    unprocessed: {
      count: unprocessedCount,
      detail: times(unprocessedCount, (index) =>
        createUntimelyInvoice({
          contractId,
          namePrefix,
          number: endRange - index,
        }),
      ),
    },
  };

  store[key] = untimelyInvoices;
  return untimelyInvoices;
}
