var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useFeatureFlags } from './FeatureFlagContext';
export default function Feature(_a) {
    var children = _a.children, feature = _a.feature, props = __rest(_a, ["children", "feature"]);
    var features = useFeatureFlags();
    var isEnabled = features[feature];
    if (!isEnabled)
        return null;
    return React.Children.map(children, function (child) {
        return React.cloneElement(child, props);
    });
}
