import { jsx as _jsx } from "react/jsx-runtime";
import { AnalyticsProvider } from './AnalyticsContext';
import useMixpanel from './useMixpanel';
export default function MixpanelProvider(_a) {
    var children = _a.children, enable = _a.enable, fallback = _a.fallback, token = _a.token;
    var mixpanel = useMixpanel({ token: token, enable: enable });
    if (mixpanel.loading || mixpanel.error) {
        return fallback;
    }
    return (_jsx(AnalyticsProvider, { value: mixpanel.value, children: children }));
}
