import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { typography } from 'styled-system';
import useResponsiveProp from './useResponsiveProp';
export default function Text(_a) {
    var align = _a.align, children = _a.children, color = _a.color, size = _a.size, weight = _a.weight, _b = _a.component, component = _b === void 0 ? 'span' : _b;
    var alignProp = useResponsiveProp(align);
    var colorProp = useResponsiveProp(color);
    var sizeProp = useResponsiveProp(size);
    var weightProp = useResponsiveProp(weight);
    var TextStyle = styled(component)(typography);
    return (_jsx(TextStyle
    // @ts-ignore
    , { 
        // @ts-ignore
        textAlign: alignProp, color: colorProp, fontSize: sizeProp, fontWeight: weightProp, children: children }));
}
