import React, { ReactNode } from 'react';
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

export const queryClient = createQueryClient();

type QueryProviderProps = {
  children: ReactNode;
  debug?: boolean;
  client?: QueryClient;
};

export default function QueryProvider({
  children,
  debug = false,
  client = queryClient,
}: QueryProviderProps) {
  return (
    <QueryClientProvider client={client}>
      {children}
      {debug ? <ReactQueryDevtools initialIsOpen={false} /> : null}
    </QueryClientProvider>
  );
}
export function createQueryClient() {
  return new QueryClient();
}

export { useQueryClient };
