var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Columns as BaseColumns } from '@bedrock-layout/columns';
import style from 'styled-components';
import useResponsiveProp from '../useResponsiveProp';
var ColumnsStyle = style(BaseColumns)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: inherit;\n  height: inherit;\n"], ["\n  width: inherit;\n  height: inherit;\n"])));
export default function Columns(_a) {
    var children = _a.children, _b = _a.space, space = _b === void 0 ? 'none' : _b, _c = _a.columns, columns = _c === void 0 ? 12 : _c;
    var spaceProp = useResponsiveProp(space);
    var columnsProp = useResponsiveProp(columns);
    return (_jsx(ColumnsStyle, { gutter: spaceProp, columns: columnsProp, children: children }));
}
var templateObject_1;
