import 'tailwindcss/components.css';
import 'tailwindcss/utilities.css';
import '@gbm/queen-ui-guidelines/css/index.css';
import 'animate.css';

import './assets/scss/theme.scss';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import LoadingScreen from './components/LoadingScreen';

import App from './App';
import { worker as mswWorker } from './msw/browser';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_MSW) {
  mswWorker.start();
} else {
  mswWorker.stop();
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingScreen />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log);
