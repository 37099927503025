// set default values from each AppEnv
// use `null` for strict values that should be set from process.env
export var environments = {
    LOCAL: {
        REACT_APP_DEBUG_LANG: true,
        ep: {
            API_DOMAIN: 'dev-gbm',
            BACKOFFICE_API_KEY: '"o5RoWdTxIt3bgDBDxS2iU59tIkj2YSayc9rS7ux7"',
            EP_AUTH_CLIENT_ID: '6n3jadb4l62dv0ig0rel2toemi',
            EP_AUTH_LOGOUT_URI: 'http://localhost:3002/signin',
            EP_AUTH_REDIRECT_URI: 'http://localhost:3002/signin',
            EP_AUTH_URL: 'https://patron-backoffice-dev-1596325.auth.us-east-1.amazoncognito.com',
            EP_BASE_URL_API: 'https://backoffice.auth.dev-gbmapi.com/v1',
            MIXPANEL_TOKEN: '',
            NEW_RELIC_ACCOUNT_ID: '',
            PORTAL_NEW_RELIC_APPLICATION_ID: '',
            BACKOFFICE_NEW_RELIC_APPLICATION_ID: '',
            NEW_RELIC_LICENSE_KEY: '',
            ROLLOUT_KEY: '603531c949a804e63d179159',
            SERVERLESS_API_KEY: '"LFurJXhik96HS5QXznmXn6odvfYyOctK6XY81Y1t"',
            VESTINGS_API_KEY: '"cnwTp0SkG94ZaYGHVchII8TNRYO7kPO83QGSHe7e"',
            API_WEBSOCKET: 'wss://47kn5syce9.execute-api.us-east-1.amazonaws.com/dev',
            NETFLIX_SSO_API_DOMAIN: 'https://meechum.netflix.com',
            NETFLIX_SSO_CLIENT_ID: 'saas-gbm-test',
            GOOGLE_SSO_CLIENT_ID: '922377396601-oj8m7344nj6dk9qqpj1fm4sgshc0tvls.apps.googleusercontent.com',
            GOOGLE_URI_REDIRECT: 'https://recordkeeping.dev-gbm.com/sso/google/callback',
        },
    },
    dev: {
        REACT_APP_DEBUG_LANG: true,
        ep: {
            API_DOMAIN: 'dev-gbm',
            BACKOFFICE_API_KEY: null,
            EP_AUTH_CLIENT_ID: '6n3jadb4l62dv0ig0rel2toemi',
            EP_AUTH_LOGOUT_URI: 'https://console.recordkeeping.dev-gbm.com/signin',
            EP_AUTH_REDIRECT_URI: 'https://console.recordkeeping.dev-gbm.com/signin',
            EP_AUTH_URL: 'https://patron-backoffice-dev-1596325.auth.us-east-1.amazoncognito.com',
            EP_BASE_URL_API: 'https://backoffice.auth.dev-gbmapi.com/v1',
            MIXPANEL_TOKEN: '',
            NEW_RELIC_ACCOUNT_ID: '',
            PORTAL_NEW_RELIC_APPLICATION_ID: '',
            BACKOFFICE_NEW_RELIC_APPLICATION_ID: '',
            NEW_RELIC_LICENSE_KEY: '',
            ROLLOUT_KEY: '603531c949a804e63d179159',
            SERVERLESS_API_KEY: null,
            VESTINGS_API_KEY: null,
            API_WEBSOCKET: null,
            NETFLIX_SSO_API_DOMAIN: 'https://meechum.netflix.com',
            NETFLIX_SSO_CLIENT_ID: 'saas-gbm-test',
            GOOGLE_SSO_CLIENT_ID: '922377396601-oj8m7344nj6dk9qqpj1fm4sgshc0tvls.apps.googleusercontent.com',
            GOOGLE_URI_REDIRECT: 'https://recordkeeping.dev-gbm.com/sso/google/callback',
        },
    },
    stg: {
        REACT_APP_DEBUG_LANG: true,
        ep: {
            API_DOMAIN: 'stg-gbm',
            BACKOFFICE_API_KEY: null,
            EP_AUTH_CLIENT_ID: '54mfc5cono5inak7viq69jdmjo',
            EP_AUTH_LOGOUT_URI: 'https://console.recordkeeping.stg-gbm.com/signin',
            EP_AUTH_REDIRECT_URI: 'https://console.recordkeeping.stg-gbm.com/signin',
            EP_AUTH_URL: 'https://patron-backoffice-stg-1596325.auth.us-east-1.amazoncognito.com',
            EP_BASE_URL_API: 'https://backoffice.auth.stg-gbmapi.com/v1',
            MIXPANEL_TOKEN: '',
            NEW_RELIC_ACCOUNT_ID: '',
            PORTAL_NEW_RELIC_APPLICATION_ID: '',
            BACKOFFICE_NEW_RELIC_APPLICATION_ID: '',
            NEW_RELIC_LICENSE_KEY: '',
            ROLLOUT_KEY: '',
            SERVERLESS_API_KEY: null,
            VESTINGS_API_KEY: null,
            API_WEBSOCKET: null,
            NETFLIX_SSO_API_DOMAIN: 'https://meechum.netflix.com',
            NETFLIX_SSO_CLIENT_ID: 'saas-gbm-test',
            GOOGLE_SSO_CLIENT_ID: '922377396601-ieaah5ik65eh0oeoljsrscp8lg0hduuo.apps.googleusercontent.com',
            GOOGLE_URI_REDIRECT: 'https://recordkeeping.stg-gbm.com/sso/google/callback',
        },
    },
    prd: {
        REACT_APP_DEBUG_LANG: true,
        ep: {
            API_DOMAIN: 'gbm',
            BACKOFFICE_API_KEY: null,
            EP_AUTH_CLIENT_ID: '29csnisvsclut2ovjdv8hqmjdo',
            EP_AUTH_LOGOUT_URI: 'https://console.recordkeeping.gbm.com/signin',
            EP_AUTH_REDIRECT_URI: 'https://console.recordkeeping.gbm.com/signin',
            EP_AUTH_URL: 'https://patron-backoffice-prd-1596325.auth.us-east-1.amazoncognito.com',
            EP_BASE_URL_API: 'https://backoffice.auth.gbmapi.com/v1',
            MIXPANEL_TOKEN: null,
            NEW_RELIC_ACCOUNT_ID: null,
            PORTAL_NEW_RELIC_APPLICATION_ID: null,
            BACKOFFICE_NEW_RELIC_APPLICATION_ID: null,
            NEW_RELIC_LICENSE_KEY: null,
            ROLLOUT_KEY: null,
            SERVERLESS_API_KEY: null,
            VESTINGS_API_KEY: null,
            API_WEBSOCKET: null,
            NETFLIX_SSO_API_DOMAIN: 'https://meechum.netflix.com',
            NETFLIX_SSO_CLIENT_ID: 'saas-gbm-prod',
            GOOGLE_SSO_CLIENT_ID: '922377396601-ro2j4s42b5kemdram6hb8jbl8opf9f1m.apps.googleusercontent.com',
            GOOGLE_URI_REDIRECT: 'https://recordkeeping.gbm.com/sso/google/callback',
        },
    },
};
var config = function () {
    var appEnv = getEnv(process.env.REACT_APP_ENV);
    var env = environments[appEnv].ep;
    // set react app env
    env.APP_ENV = appEnv;
    // replace default values with one defined in `process.env`
    Object.keys(process.env).forEach(function (key) {
        if (key.includes('REACT_APP_') && process.env[key] != null) {
            var cleanKey = key.replace('REACT_APP_', '');
            env[cleanKey] = process.env[key];
        }
    });
    Object.keys(environments[appEnv].ep).forEach(function (key) {
        if (env[key] == null) {
            throw new Error("Please enter a custom ".concat(key, " value"));
        }
    });
    return env;
};
function getEnv(appEnv) {
    var environment = appEnv || 'LOCAL';
    if (Object.keys(environments).indexOf(environment) < 0) {
        environment = 'LOCAL';
    }
    return environment;
}
export default config;
