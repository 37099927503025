var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Stack as StackBase } from '@bedrock-layout/stack';
import styled from 'styled-components';
import useResponsiveProp from '../useResponsiveProp';
var StackStyle = styled(StackBase)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: inherit;\n"], ["\n  width: inherit;\n"])));
export default function Stack(_a) {
    var children = _a.children, _b = _a.space, space = _b === void 0 ? 'none' : _b;
    var spaceProp = useResponsiveProp(space);
    return _jsx(StackStyle, { gutter: spaceProp, children: children });
}
var templateObject_1;
