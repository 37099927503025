var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
/* eslint-disable camelcase */
import axiosRetry from 'axios-retry';
import config from '../config/config';
import { storage } from '../storage';
// FIXME: find a better way to get memory key
var ACCESS_TOKEN_MEMORY_KEY = 'access_token';
var env = config();
var api = axios.create({
    baseURL: "https://api.recordkeeping.".concat(env.API_DOMAIN, ".com/v2"),
    headers: {
        'Content-type': 'application/json',
        'x-api-key': JSON.parse(env.SERVERLESS_API_KEY),
    },
});
// HACK: use retry to avoid cold start issue
axiosRetry(api, { retryCondition: function () { return true; } });
api.interceptors.request.use(function (requestConfig) {
    var accessToken = storage.get(ACCESS_TOKEN_MEMORY_KEY);
    var headers = new axios.AxiosHeaders(requestConfig.headers);
    headers.set('Authorization', "Bearer ".concat(accessToken));
    return __assign(__assign({}, requestConfig), { headers: headers });
});
api.interceptors.response.use(function (response) { return response; }, function (error) {
    var newrelic = window.newrelic;
    newrelic.noticeError(error);
    return Promise.reject(error);
});
var userIdentityDataSource = {
    getUserIdentityPermissions: function (email, cancelToken) { return __awaiter(void 0, void 0, void 0, function () {
        var response, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.get("/user-identities/".concat(email, "/permissions"), { cancelToken: cancelToken })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    err_1 = _a.sent();
                    // Verifica si el error es una instancia de AxiosError
                    if (axios.isAxiosError(err_1)) {
                        if (err_1.response != null) {
                            throw err_1.response;
                        }
                        if (err_1.request != null) {
                            throw err_1.request;
                        }
                    }
                    throw err_1;
                case 3: return [2 /*return*/];
            }
        });
    }); },
};
var dataSource = {
    userIdentity: userIdentityDataSource,
};
export default dataSource;
