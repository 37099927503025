import { Button, Card } from '@gbm/queen-ui-guidelines';
import Text from '@recordkeeping/common/build/Text';
import { useAuth } from '@recordkeeping/common/build/auth/AuthContext';
import { useTranslation } from '@recordkeeping/common/build/internationalization/InternationalizationContext';
import React, { useEffect, useCallback } from 'react';

import {
  useHistory,
  useLocation,
} from '@recordkeeping/common/build/navigation';

import {
  Box,
  ContentBlock,
  Padding,
  Stack,
} from '@recordkeeping/common/build/layout';

import IconGbmLogo from 'assets/images/logo-rk.png';

export default function SignIn() {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const t = useTranslation();

  const [, code] = location.search.split('?code=');

  const loginWithCode = useCallback(async () => {
    try {
      await auth?.login(code);
      history.push('/');
    } catch {
      history.push('/signin');
    }
  }, [auth, code, history]);

  const validateCurrentSession = useCallback(async () => {
    try {
      const isAuthenticated = await auth?.checkAuth();
      if (isAuthenticated) {
        history.push('/');
      } else {
        history.push('/signin');
      }
    } catch {
      history.push('/signin');
    }
  }, [auth, history]);

  useEffect(() => {
    if (code != null) {
      loginWithCode();
    } else {
      validateCurrentSession();
    }
  }, [loginWithCode, validateCurrentSession, location.search, code]);

  function handleLogin() {
    auth?.login();
  }

  if (code != null) {
    return null;
  }

  return (
    <Padding padding="lg" paddingTop="xxl">
      <ContentBlock width={475}>
        <Card>
          <Padding padding="xxl">
            <Box align="center">
              <Stack space="xxl">
                <Text align="center" size="2.25rem">
                  {t('login.message')}
                </Text>

                <img src={IconGbmLogo} alt="gbm" className="img-fluid" />

                <Button onClick={handleLogin}>
                  <Text color="white">{t('login.button')}</Text>
                </Button>
              </Stack>
            </Box>
          </Padding>
        </Card>
      </ContentBlock>
    </Padding>
  );
}
