const currentYear = new Date().getFullYear();

export const client = {
  Client_Id: '20015',
  Client_Name: '20015 - CLIENTE NO. 20015',
};

export const contract = {
  Client_Id: client.Client_Id,
  Contract_Id: '100031',
  Contract_Name: 'Contract Name',
  Product_Name: 'Product Name',
  Prefix: 'RFC_CONTRATO_502020',
};

export const detail = {
  Participant_Identifier: '10003',
  Description_DocType: 'CFDI No Solicitado',
  Contract_Id_SOB: contract.Contract_Id,
  Participant_Name: 'PARTICIPANTE NO. 10003',
  Contact_Email: '10003@email.com',
  Amount: 1000,
  Contribution: 2000,
  Interest: 3000,
  Withdrawal: 4000,
  SendMaiDate: new Date(`${currentYear}-12-14T00:00:00`),
  Document_Id: 1,
  Product_Name: 'Product Name',
  Fiscal_Year: currentYear,
  NameFile: 'some/path/name',
};

export const detailRequest = {
  participant_id: '10003',
  clave: 'C10003',
  contract_id: contract.Contract_Id,
  contract_sob: contract.Contract_Id,
  contribution: 1000,
  curp: 'SOMECURP',
  document_id: 1,
  email: '10003@email.com',
  fiscal_year: 2020,
  interest: 2000,
  loan: 3000,
  name_file: 'some/path/name',
  name: 'PARTICIPANTE NO. 10003',
  rfc_origin: 'SOMERFC',
  rfc: 'SOMERFC',
  status: 'CFDI No Solicitado',
  total: 4000,
};

export const clientAccountDetail = {
  description: 'AP EMPLEADO',
  type_collection: [
    { id_contract: contract.Client_Id, id: 4812, id_parent: 4812 },
  ],
};

export const clientConfig = {
  rules: {
    no_rules: [{ value: 0.0 }],
    age: [{ min: 0.0, max: 0.0 }],
    seniority: [{ min: 5.0, max: 5.0 }],
    contribution_time: [{ min: 0.0, max: 0.0 }],
    percents: [{ value: 100.0 }],
  },
  client_id: client.Client_Id,
  id: '00000000-0000-0000-0000-000000000001',
  status: 'ACTIVE',
  description: 'Configuración de Prueba',
  accounts: [clientAccountDetail],
  client_name: `20015 - CLIENTE NO. 20015`,
  contracts: [contract.Contract_Id],
  _contracts: [contract],
  fecha: '1/20/2021 5:03:37 PM',
  is_template: false,
  pension: { no_rule: 0 },
  name: 'Tabla de Prueba',
  pension_before: { no_rule: 0, msg_pension_before: null },
};

export const untimelyInvoice = {
  file_name: 'GGB080116EZ0_50202000N930000065',
  participant_name: 'Participante Con Apellido',
  participant_identifier: '1',
  rfc: 'XAXX010101000',
};
