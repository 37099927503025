import '@recordkeeping/common/src/notifications/Notification.scss';

import MixpanelProvider from '@recordkeeping/common/build/analytics/MixpanelProvider';
import GBMAuthProvider from '@recordkeeping/common/build/auth/GBMAuthProvider';
import useConfig from '@recordkeeping/common/build/config';
import { DataProvider } from '@recordkeeping/common/build/data/DataContext';
import elPatronDataProvider from '@recordkeeping/common/build/data/elPatronDataProvider';
import recordKeepingDataSource from '@recordkeeping/common/build/data/recordKeepingDataSource';
import RollOutProvider from '@recordkeeping/common/build/featureFlags/RollOutProvider';
import I18nextProvider from '@recordkeeping/common/build/internationalization/I18nextProvider';
import NewRelicProvider from '@recordkeeping/common/build/monitoring/NewRelicProvider';
import HotToastProvider from '@recordkeeping/common/build/notifications/HotToastProvider';
import React, { ReactNode } from 'react';

import LoadingScreen from '../components/LoadingScreen';
import QueryProvider from '../http/QueryProvider';

type InfrastructureProviderProps = {
  children: ReactNode;
};

export default function InfrastructureProvider({
  children,
}: InfrastructureProviderProps) {
  const config = useConfig();

  return (
    <HotToastProvider>
      <I18nextProvider>
        <RollOutProvider token={config.ROLLOUT_KEY}>
          <MixpanelProvider
            enable={config.APP_ENV === 'prd' || config.ENABLE_MIXPANEL}
            fallback={<LoadingScreen />}
            token={config.MIXPANEL_TOKEN}
          >
            <NewRelicProvider
              enable={config.APP_ENV === 'prd' || config.ENABLE_NEW_RELIC}
            >
              <QueryProvider debug={config.DEBUG_HTTP}>
                <DataProvider
                  value={{
                    auth: elPatronDataProvider,
                    recordKeeping: recordKeepingDataSource,
                  }}
                >
                  <GBMAuthProvider>{children}</GBMAuthProvider>
                </DataProvider>
              </QueryProvider>
            </NewRelicProvider>
          </MixpanelProvider>
        </RollOutProvider>
      </I18nextProvider>
    </HotToastProvider>
  );
}
