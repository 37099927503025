/* eslint-disable camelcase */
import faker from 'faker';
import sample from 'lodash/sample';

import { PostInvoiceResponseBody } from 'api/types';
import { InvoiceStatus } from 'invoices/InvoiceRequest';

import { InvoicePostBody } from './types';

export default function createInvoicePostResponseBody(
  body: InvoicePostBody,
): PostInvoiceResponseBody {
  return {
    fiscal_year: body.fiscalYear,
    contracts: body.contracts.join(','),
    participants: body.participantsInvoicesData.map((participant) => {
      let process_message: string | undefined;
      const status = sample<InvoiceStatus>(['PROCESSED', 'ERROR'])!;
      if (status === 'ERROR') {
        process_message = faker.lorem.sentence();
      }

      return {
        status,
        process_message,
        participant_id: participant.participantId,
        retention: participant.retention,
        contract: sample(body.contracts)!,
      };
    }),
  };
}
