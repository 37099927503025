import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useHistory } from '../navigation';
import { useIdentity } from './AuthContext';
export default function Authenticated(_a) {
    var children = _a.children, redirect = _a.redirect;
    var history = useHistory();
    var _b = useIdentity(), identity = _b.identity, loading = _b.loading;
    useEffect(function () {
        if (identity == null && !loading) {
            history.replace(redirect);
        }
    }, [identity, loading, history, redirect]);
    return _jsx(_Fragment, { children: children });
}
