import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { NOTIFICATION_DURATION, NotificationProvider, } from './NotificationContext';
export default function HotToastProvider(_a) {
    var children = _a.children;
    var display = useCallback(function (message, opts) {
        if (opts === void 0) { opts = {}; }
        var _a = opts.color, color = _a === void 0 ? 'primary' : _a, _b = opts.duration, duration = _b === void 0 ? NOTIFICATION_DURATION : _b, id = opts.id, loadingIndicator = opts.loadingIndicator, customClass = opts.customClass, icon = opts.icon;
        var classNames = clsx('notification-default', customClass, {
            'notification-default--danger': color === 'danger',
            'notification-default--dark': color === 'dark',
            'notification-default--info': color === 'info',
            'notification-default--light': color === 'light',
            'notification-default--primary': color === 'primary',
            'notification-default--secondary': color === 'secondary',
            'notification-default--success': color === 'success',
            'notification-default--warning': color === 'warning',
        });
        var body = {
            duration: duration,
            className: classNames,
            icon: icon !== null && icon !== void 0 ? icon : undefined,
        };
        if (id != null) {
            body.id = id;
        }
        if (loadingIndicator) {
            delete body.duration;
            return toast.loading(message, body);
        }
        return toast(message, body);
    }, []);
    var dismiss = useCallback(function (notificationId) {
        return toast.dismiss(notificationId);
    }, []);
    var value = useMemo(function () { return ({ display: display, dismiss: dismiss }); }, [dismiss, display]);
    return (_jsxs(NotificationProvider, { value: value, children: [children, _jsx(Toaster, { position: "top-center", toastOptions: {
                    className: 'notification-default',
                    style: {
                        width: '110vw',
                        border: '1px solid transparent',
                        borderRadius: '.25rem',
                        marginBottom: '1rem',
                        padding: '.75rem 1.25rem',
                        position: 'relative',
                    },
                } })] }));
}
