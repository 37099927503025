import decode from 'jwt-decode';
import { storage } from '../storage';
export var TOKEN_MEMORY_KEY = '_token';
export var REFRESH_TOKEN_MEMORY_KEY = '_refreshToken';
export var IDENTITY_MEMORY_KEY = '_identity';
function getMemoryValue(memoryKey) {
    var value = storage.get(memoryKey);
    if (value == null) {
        throw new Error("invalid value for ".concat(memoryKey));
    }
    return value;
}
export function getToken() {
    return getMemoryValue(TOKEN_MEMORY_KEY);
}
export function getDecodedToken() {
    return decode(getMemoryValue(TOKEN_MEMORY_KEY));
}
export function getRefreshToken() {
    return getMemoryValue(REFRESH_TOKEN_MEMORY_KEY);
}
export function getIdentity() {
    if (!isValidSession()) {
        return null;
    }
    var identityString = getMemoryValue(IDENTITY_MEMORY_KEY);
    return JSON.parse(identityString);
}
export function isValidSession() {
    var token = getDecodedToken();
    if (token.exp == null) {
        return false;
    }
    // convert expiration in seconds to milliseconds
    return new Date(token.exp * 1000) > new Date();
}
export function storeSession(options) {
    storage.set(TOKEN_MEMORY_KEY, options.token);
    storage.set(REFRESH_TOKEN_MEMORY_KEY, options.refreshToken);
    storage.set(IDENTITY_MEMORY_KEY, JSON.stringify(options.identity));
}
export function removeSession() {
    storage.remove(TOKEN_MEMORY_KEY);
    storage.remove(REFRESH_TOKEN_MEMORY_KEY);
    storage.remove(IDENTITY_MEMORY_KEY);
}
export default {
    getToken: getToken,
    getDecodedToken: getDecodedToken,
    getRefreshToken: getRefreshToken,
    getIdentity: getIdentity,
    isValidSession: isValidSession,
    storeSession: storeSession,
    removeSession: removeSession,
};
