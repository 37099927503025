export function getDocumentsDetailParams(req) {
  const rowsIni = parseInt(req.url.searchParams.get('rows_ini'), 10);
  const rowsPage = parseInt(req.url.searchParams.get('rows_page'), 10);
  const year = parseInt(req.url.searchParams.get('year'), 10);
  const contractsIds = req.url.searchParams.get('contracts_ids');

  return {
    rowsIni,
    rowsPage,
    year,
    contractsIds,
  };
}

export function getDocumentsRequestDetailParams(req) {
  const rowsPage = parseInt(req.url.searchParams.get('rows_page'), 10);
  const rowsIni = parseInt(req.url.searchParams.get('rows_ini'), 10);
  const year = parseInt(req.url.searchParams.get('year'), 10);
  const contractsIds = req.url.searchParams.get('contracts_ids');

  return {
    rowsIni,
    rowsPage,
    year,
    contractsIds,
  };
}

export function getConfigParams(req) {
  const clientId = req.url.searchParams.get('client_id');
  if (clientId == null) {
    return {};
  }

  return {
    clientId,
  };
}
