import { http, HttpResponse } from 'msw';

import createInvoice from './invoices/createInvoice';
import createInvoicePostResponseBody from './invoices/createInvoicePostResponseBody';
import parseInvoiceGetBody from './invoices/parseInvoiceGetBody';
import parseInvoicePostBody from './invoices/parseInvoicePostBody';
import parseNumericParam from './invoices/parseNumericParam';
import randomNumber from './randomNumber';

import createInvoiceGetResponseBody, {
  createInvoiceResponseFromInvoice,
} from './invoices/createInvoiceGetResponseBody';

export const handlers = [
  http.post(
    'https://api.recordkeeping.dev-gbm.com/v2/invoices',
    ({ request }) => {
      const body = request.body as Record<string, any>;
      const [parsedBody, error] = parseInvoicePostBody(body);
      if (error != null || parsedBody === undefined) {
        return HttpResponse.json(error, { status: 403 });
      }

      const resBody = createInvoicePostResponseBody(parsedBody);
      return HttpResponse.json(resBody, { status: 201 });
    },
  ),
  http.get(
    'https://api.recordkeeping.dev-gbm.com/v2/invoices/:documentId',
    ({ request, params }) => {
      const url = new URL(request.url);
      try {
        const fiscalYear = parseNumericParam(url.searchParams, 'fiscal_year')!;

        const documentId = parseNumericParam(
          url.searchParams,
          'documentId',
          true,
        )!;
        const invoice = createInvoice({
          year: fiscalYear,
          contractId: randomNumber(),
        });

        invoice.documentId = documentId;
        const resBody = createInvoiceResponseFromInvoice(invoice);
        return HttpResponse.json(resBody, { status: 200 });
      } catch (err) {
        return HttpResponse.json({ err }, { status: 403 });
      }
    },
  ),
  http.get(
    'https://api.recordkeeping.dev-gbm.com/v2/invoices',
    ({ request }) => {
      const url = new URL(request.url);
      const [parsedBody, error] = parseInvoiceGetBody(url.searchParams);
      if (error != null || parsedBody === undefined) {
        return HttpResponse.json(error, { status: 403 });
      }

      const resBody = createInvoiceGetResponseBody(parsedBody);
      return HttpResponse.json(resBody, { status: 200 });
    },
  ),
];
